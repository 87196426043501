exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-curator-js": () => import("./../../../src/pages/curator.js" /* webpackChunkName: "component---src-pages-curator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-museum-js": () => import("./../../../src/pages/museum.js" /* webpackChunkName: "component---src-pages-museum-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/Artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-artworks-js": () => import("./../../../src/templates/Artworks.js" /* webpackChunkName: "component---src-templates-artworks-js" */)
}

