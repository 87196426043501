import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import { Helmet } from "react-helmet";
import Header from "./header";
import Footer from "./Footer";
// import "./layoutStyle.css";
import styled from "styled-components";

const LayoutContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex: 1 0 auto;
    max-width: 110rem;
    margin: 0 auto;
  }
`;

export default function Layout({ children }) {
  return (
    <LayoutContainer>
      <GlobalStyles />
      <Header siteTitle={`Title`} />
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      > */}
      <main>{children}</main>
      <Footer />
      {/* </div> */}
    </LayoutContainer>
  );
}
