import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";

const HeadContainer = styled.header`
  /* background-color: var(--brand-blue); */
  width: 100%;
  min-height: 100px;
  border-bottom: 3px solid var(--brand-blue);
  flex-shrink: 1;
  h1 {
    font-size: 4rem;
  }
`;

const Head = styled.div`
  padding: 1.45rem 1.0875rem;
`;

const Navigation = styled.ul`
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: white;
  z-index: 100;
  border-bottom-left-radius: 10px;
  li {
    font-size: 2rem;
    padding: 0.5rem 1rem;
    display: inline-block;
    text-transform: uppercase;
  }
`;

const Header = ({ siteTitle }) => (
  <HeadContainer>
    <Head>
      <motion.h1
        transition={{ duration: 2 }}
        animate={{ opacity: [0, 1], x: [300, 0] }}
        style={{ margin: 0 }}
      >
        <Link
          to="/"
          style={{
            color: `var(--brand-blue)`,
            textDecoration: `none`,
          }}
        >
          {/* {s
            iteTitle} */}
          M O M H
        </Link>
      </motion.h1>
      <Navigation>
        <li>
          <Link to="/museum">Museum</Link>
        </li>
        <li>
          <Link to="/art">Art</Link>
        </li>
        <li>
          <Link to="/artists">Artists</Link>
        </li>
        <li>
          <Link to="/curator">Curator</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </Navigation>
    </Head>
  </HeadContainer>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `MOMH`,
};

export default Header;
