import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import styled from "styled-components";
import "./layoutStyle.css";
import Month from "./formatMonth";

const FooterContainer = styled.footer`
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  /* flex-shrink: 0; */
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgba (255, 255, 255, 0.7);
  background-color: var(--brand-blue);
  font-size: 0.8em;
  position: sticky;
  bottom: 0px;
  width: 100%;
  p {
    margin: 0;
    padding: 0;
  }
  .footerLink {
    color: rgba (255, 255, 255, 0.7) !important;
  }
`;

export default function Footer() {
  return (
    <FooterContainer
      style={{
        marginTop: `2rem`,
      }}
    >
      <p>
        © 2021 - {new Date().getFullYear()}, built by Nicholas Shaw:
        {` `}
        <a className="footerLink" href="https://www.nicholas-shaw.com">
          nicholas-shaw.com
        </a>
      </p>
    </FooterContainer>
  );
}
