import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,900&family=Assistant:wght@200;300;700&family=IBM+Plex+Sans+Thai+Looped:wght@100;200&display=swap');

:root {
    --brand-blue: #1e293a;
    --brand-beige: #918a6f;
    --brand-beige-light: #918a6f;
    --brand-green: #536c53;
    --brand-blue: #1e293a;
    /* --text-color: #FFFFFFBB; */
    --text-color: #1e293a;
    --highlight: #72ccbc;
    --default-box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
}

html  {
    /* background: rgb(30,41,58);
    background: linear-gradient(10deg, rgba(30,41,58,1) 0%, rgba(30,41,58,1) 10%, rgba(30,41,58,0.5) 41%, rgba(30,41,58,1) 89%, rgba(30,41,58,1) 100%);     */
    
    height: 100vh;
    color: var(--text-color);
    font-size: 10px;
}
body {
    background-image: url("/images/gradientBackground.jpg");
    font-family: "Assistant", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
}

a {
    position: relative;
    text-decoration: none;
    color: var(--text-color);
    transition: color 0.2s ease-in-out;
    border-bottom: 1px solid rgba(0,0,0,0);
    :hover {
        border-bottom: 1px solid var(--highlight);
        /* text-decoration: underline;   */
        cursor: pointer;
        color: var(--highlight);
    }
}

.pageContent {
    padding: 2rem;
    max-width: 110rem;
}

.artworkMarker {
    fill: var(--highlight);
    fill-opacity: 0;
    stroke-opacity: 0;
    transition: all 1s ease-in-out;
    :hover {
        stroke-opacity: 0.5;
        stroke: var(--highlight);
        cursor: pointer;
    }
}

`;

export default GlobalStyles;
